._36cDyA {
}
._3WAZHf {
  display: none
}
._3WAZHf,
  ._3WAZHf:after,
  ._3WAZHf:before,
  ._3WAZHf *,
  ._3WAZHf *:after,
  ._3WAZHf *:before {
    box-sizing: border-box
  }
._3WAZHf::selection, ._3WAZHf:after::selection, ._3WAZHf:before::selection, ._3WAZHf *::selection, ._3WAZHf *:after::selection, ._3WAZHf *:before::selection {
      background: none;
    }
._1jQahN {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 18px;
  cursor: pointer
}
._1jQahN:before,
  ._1jQahN:after {
    content: '';
    position: absolute;
    margin: 0;
    outline: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    transition: all 0.3s ease-in;
  }
._1jQahN:before {
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.3);
    left: 0px;
    width: 46px;
    height: 18px;
    border-radius: 9px;
  }
._1jQahN:after {
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14),
      0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
  }
._1jQahN.tmKMZD:before {
      background-color: #eee !important;
    }
._1jQahN.tmKMZD:after {
      background-color: #b6b6b6 !important;
    }
._14ZIOj {
}
._14ZIOj:after {
    left: 28px;
  }
.rtl ._3uankN:after {
    left: 28px;
  }
.rtl ._14ZIOj:after {
    left: 0px;
  }
._1K7zmO {
  margin-left: 10px;
  color: white;
  font-weight: bold;
}
