._37cg5a {
  padding: 0 20px;
  background: white;
  color: #444;
}

._31kmmM {
  min-height: 35px;
  width: 100%;
}

._31kmmM + ._31kmmM {
  border-top: 1px solid #eee;
}

.mYp4M0 {
  width: 34px;
  height: 34px;
  margin: 2px;
}

._2cUpq2 {
  cursor: default;
  width: 32px;
  height: 32px;
}

._4mnU-T {
}

._3C7VTZ {
}

._1UFZDQ {
  width: 100%;
}

._2KtWQw {
  text-align: center;
}

._1NmUR3 {
  height: 26px;
  width: 26px;
}
