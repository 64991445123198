._18mBSw {
  width: 640px;
  padding: 40px 0;
  background: #ffffff;
}

  ._18mBSw svg {
    width: 100px;
    height: 100px;
  }
.qkR-w4 {
  font-family: inherit;
  font-weight: 300;
  margin: 20px 0 40px 0;
  text-align: center;
  padding: 0 20px;
}
._1pyshl {
  text-align: center;
  margin: 0 10%;
  max-width: 80%;
}
._13Aw33 {
  width: 100%;
  margin: 20px 0 40px 0;
  border-bottom: 1px solid #eee;
  box-shadow: inset 0px -5px 6px -2px rgba(255, 255, 255, 1);
}
.iTabVk {
  margin: 0 10px 4px 10px;
  padding: 0 65px;
  max-height: 200px;
  list-style: disc outside;
  overflow-y: auto;
  color: #727272
}
.iTabVk::-webkit-scrollbar {
    width: 6px;
  }
.iTabVk::-webkit-scrollbar-thumb {
    background: #418ac9;
  }
._2zTJPV {
  margin-bottom: 10px;
}
._2zTJPV:last-child {
  margin-bottom: 0;
}
._2IXI_8 {
  height: 45px;
}
._1BMJsh._18mBSw {
  width: 100%;
  text-align: center;
}
._1BMJsh._18mBSw ._13Aw33 {
    border: none;
  }
._1BMJsh._18mBSw .iTabVk {
    max-height: 100%;
    margin: 0 10px;
  }
._1BMJsh._18mBSw ._2zTJPV {
    text-align: left;
  }
._1BMJsh._18mBSw ._2IXI_8 {
    display: none;
  }
