._1Z0pYk {
  background: #ffffff;
  width: 570px;
  padding: 40px;
  z-index: 1;
}
/* Important for IE */
._1dkWan {
  max-width: 570px;
}
.gxnYzT,
.wWkGhr,
._12lRMm {
  margin-bottom: 20px;
}
.wWkGhr {
  font-size: 36px;
  text-align: center;
}
._12lRMm {
  font-size: 14px;
  text-align: center;
  margin-left: 40px;
  margin-right: 40px;
}
._2pUtQt {
  font-size: 18px;
  font-weight: 500
}
._2pUtQt:last-child {
    margin-left: 20px;
  }
@media (max-width: 600px) {
  ._3sWCsC {
    width: 100%;
  }
  ._2pUtQt {
    width: 100%
  }
    ._2pUtQt:last-child {
      margin-top: 10px;
      margin-left: 0;
    }
}
