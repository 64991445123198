._2UulPr {
  padding: 30px 20px 50px;
}
._33JTZj {
  text-align: center;
  padding: 50px 0;
  font-size: 20px;
}
._1rPF0T {
  color: #009bbb;
}
._20RNyR {
  margin: 30px 0 70px;
  text-align: center;
  color: #4d4f53;
  font-weight: 100;
}
._3JdU66 {
  font-size: 24px;
}
.ObHyOB {
  font-size: 60px;
}
.nsawMT ._20RNyR {
    margin: 30px 0;
  }
