._1BxQg8 {
  max-width: 750px;
  min-width: 100%;
  margin: 0 15px;
}

._2j-p9Y {
  text-align: center;
  min-height: 21px;
}

._3hbwpj {
  padding-top: 40px;
}

._24EkBW {
  border-radius: 0;
}

._1UXc2V {
  border-radius: 0;
}

._3pV58h {
}

._3tsbJ6 {
  margin-top: 10px;
}

._3tsbJ6 label,
  ._3tsbJ6 div {
    color: #727272 !important;
  }

._3tsbJ6 > div {
    width: 95px;
  }
