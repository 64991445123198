._1ezx4I {
  padding-top: 20px;
  margin-bottom: 40px;
}
._2XVgG_ {
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
}
._1HF-2U {
  width: 520px;
}
.nMFMMR {
  height: 50px;
}
.OS-mUX {
  font-size: 30px;
  font-weight: 300;
}
._3RsSYW {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
}
._1djOOD {
  max-width: 150px;
}
._1djOOD input,
._1djOOD span,
._1djOOD input::-webkit-input-placeholder {
  color: #ffffff;
}
._3qwNcG {
  position: relative;
}
._2jhK5k {
  width: 18px;
  height: 18px;
  margin-right: 15px;
}
._2BTefD {
  margin-left: 20px;
}
._3zQGyV {
  margin-top: 40px;
}
._34kd4v {
  margin: 5px 0 20px 0;
}
._1VhPDk {
  background: #000000;
  display: -webkit-inline-flex;
  display: inline-flex;
  padding: 7px 9px;
  border-radius: 5px;
  border: 1px solid transparent;
}
._1VhPDk:first-child {
  margin-right: 10px;
}
._3HUSN0 {
  width: 117px;
  height: 26px;
}
.GsiYGQ {
  max-width: 60%;
}
._2KAU7E {
  position: absolute;
  top: 100%;
  right: 0;
  left: 50%;
}
._3qwNcG > div:first-of-type ._2KAU7E {
    left: 0;
    right: 50%;
  }
@media all and (max-width: 959px) {
  ._2XVgG_ {
    padding: 0 20px;
  }
}
