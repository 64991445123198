/**
 * Handle z-index layering appropiately.
 * @see https://github.com/mozioinc/mozio-web/issues/61
 * @see https://css-tricks.com/handling-z-index/
 *
 * The variables are declared to use by importing when we need further calculations
 * to build more granular layering, like calc($modal + 600 + 50)
 *
 * Classes that use these variables are declared in 'partials/layers'
 * to use with composition when no further granularity is required.
 */
/* Elements that cover the entire screen */
/* Dialogs and modals. */
/* Elements usually triggered by hovering or activating inputs by the user. */
/* Elements that support the overall layout, such as a fixed header or footer. */
/* Elements that stand out for a particular reason. */
/* Reserved for the background cover. */
._12J0PJ {
  position: relative;
  padding: 0 10px;
}
@media all and (min-width: 601px) {
    ._27njzH ._3pJqQ_ {
      height: auto !important;
      overflow: visible !important;
      transition: none !important;
    }
    ._27njzH .ApcuQ_ {
      display: none;
    }
  }
@media all and (max-width: 600px) {
    ._27njzH ._3pJqQ_ {
      clear: both;
    }
      ._27njzH ._3pJqQ_ .eB2GnA {
        padding-bottom: 10px;
      }
      ._27njzH ._3pJqQ_ ._2Oyx13 {
        clear: both;
        float: none;
      }
      ._27njzH ._3pJqQ_ ._8C8WYI {
        float: none;
      }
    ._27njzH ._1CbhWF {
      position: fixed;
      top: -5px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      overflow: auto;
      box-shadow: none;
      width: auto;
      min-width: 0;
    }
    ._27njzH ._3EVoin {
      display: none !important;
    }
    ._27njzH ._13oY9r:after {
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
    }
    ._27njzH ._3Q0Fy0 {
      display: block;
    }
    ._27njzH ._23jviC {
      position: absolute;
      top: 50px;
      bottom: 0;
      overflow: auto;
      width: 100%;
    }
    ._27njzH ._3DO9YT {
      margin-right: 6px;
    }
  }
/* Nav element */
._2CzV2j {
}
._3pJqQ_ {
}
/* Logo */
.X2U7jh {
  height: 100%;
  width: 100%;
}
._1ypb5f {
  display: block;
  cursor: pointer;
  margin-left: 4px;
  width: 95%;
}
.I2EwsZ {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 50%;
}
._1kFO5J {
}
._3DO9YT {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  margin-right: 15px;
}
/* Dropdown */
._1NDPox {
  position: relative;
}
._13oY9r {
}
._13oY9r ._26Y-Qx {
    padding-right: 24px;
  }
._13oY9r:after {
  pointer-events: none;
  content: '';
  -webkit-transform: translateY(-1px) rotate(-135deg);
          transform: translateY(-1px) rotate(-135deg);
  width: 6px;
  height: 6px;
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -3px;
}
._2Jt8lz ._1CbhWF {
    display: block;
  }
._2Jt8lz ._3EVoin {
    display: block;
  }
._1CbhWF {
  display: none;
  position: absolute;
  background: #ffffff;
  box-shadow: 0 0 8px rgb(207, 207, 207);
  border-radius: 3px;
  top: 100%;
  -webkit-transform: translateY(5px);
          transform: translateY(5px);
  right: 0;
  z-index: 7000;
  min-width: 100%;
}
._3EVoin {
  display: none;
  top: 100%;
  right: 50%;
  position: absolute;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 3px 3px 0 #b6b6b6;
  z-index: 7100;
}
._3EVoin:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  margin-left: 4px;
  box-sizing: border-box;
  border: 8px solid #ffffff;
  border-color: transparent transparent #ffffff #ffffff;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: translateY(9px) rotate(135deg);
          transform: translateY(9px) rotate(135deg);
  box-shadow: -3px 3px 3px -3px #b6b6b6;
}
._3Q0Fy0 {
  position: relative;
  display: none;
  text-align: center;
  padding: 12px 0;
  border-bottom: 1px solid #ddd;
  height: 50px;
  box-sizing: border-box;
  font-size: 18px;
}
._3Gz5o5 {
  position: absolute;
  left: 12px;
  top: 50%;
  margin-top: -11px;
}
/* Buttons */
._2Oyx13 {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
._2YMcgm {
  float: left;
}
.hhiZzi {
  float: right;
}
/* Button */
._8C8WYI {
  cursor: pointer;
  float: left;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 400;
  font-size: 14px;
  transition: background-color 0.3s ease;
}
._26Y-Qx {
  padding: 0 10px;
  text-decoration: none;
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  transition: color 0.3s ease;
}
._1ukL0M {
  font-size: 11px;
}
._1VLMDI,
._3tdfbv {
  cursor: default;
}
._1VLMDI {
}
._3tdfbv {
}
/* Hamburger */
.ApcuQ_ {
  margin-right: -3px;
}
._2wmS7_ {
}
._13DdNU {
}
