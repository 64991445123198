.DXORnb {
  height: 420px;
  padding: 0 5px;
  text-align: center;

  overflow: hidden;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}
._1lbneG {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding-top: 20px;
}
._1vWOHD {
  font-size: 60px;
  line-height: 60px;
  font-weight: 100;
  color: #ffffff;
}
._3fUNKO {
  margin-top: 38px;
  text-decoration: none;
}
._3fUNKO ._1iyJtL {
    color: #ffffff;
  }
._3fUNKO ._8Jefqi {
    color: #428bca;
  }
._3kk3hB {
  padding: 9px 25px;
  min-width: 200px;
  margin: 0 20px 20px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid #ffffff;
  display: inline-block;
  transition: background-color 0.2s ease
}
._3kk3hB._1iyJtL {
    background-color: transparent
  }
._3kk3hB._1iyJtL:hover {
      background-color: #315375;
    }
._3kk3hB._8Jefqi {
    background-color: #ffffff
  }
._3kk3hB._8Jefqi:hover {
      background-color: #eee;
    }
.corp-atpi ._1vWOHD {
    color: #00245e;
  }
.corp-atpi ._3kk3hB._8Jefqi {
      display: none;
    }
