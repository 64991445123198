._2oNo5f {
  margin-top: 22px;
  border: solid 1px #e4e4e4;
  padding: 20px 15px 20px 15px;
}
  ._2pqrLZ ._2oNo5f {
    padding: 20px 20px 20px 20p;
  }
._3PlKtA {
  height: 18px;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 7px;
}
._13t6VC {
  background: url(/51d157424e11844e2d281b3371d20050.png) no-repeat;
  height: 30px;
  width: 100%;
}
._3CqKgW {
  margin-top: 10px;
  min-height: 48px;
  font-size: 12px;
  color: #212121;
}
