._1K9CW9 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  padding: 2px 4px;
}

.abPeq4 {
  margin-right: 10px;
  margin-top: 3px;
  width: 16px;
  height: 16px;
}

._1o0EXC {
  font-size: 12px;
  font-weight: 500;
}

/* GBT specific styles */

.corp-gbt ._1K9CW9 {
    background-color: rgba(40, 112, 210, 0.1) !important;
  }

.corp-gbt ._1o0EXC {
    color: #2870d2 !important;
  }

.corp-gbt .abPeq4 {
    fill: #2870d2 !important;
  }
