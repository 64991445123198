._13gvoT {
  padding: 100px;
}
._28DGzr {
  max-width: 50%;
  min-width: 380px;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 2px 2px 10px -2px #000000;
  text-align: center;
}
.bns0xP {
  margin: 0 auto;
  width: 100%;
}
.bns0xP img {
    height: 48px !important;
    width: 120px;
  }
._13xsSD {
  margin: 40px 0 45px;
  padding: 0 35px;
  font-size: 24px;
  color: #212121;
}
._2BT2AJ {
  margin-top: 50px;
}
._1d8akG span {
    margin-right: 30px
  }
._1d8akG span:last-child {
      margin-right: 0;
    }
._2EIVfT {
  padding: 0;
  border-bottom: 1px solid #ddd;
}
._2EIVfT ._28DGzr {
    min-width: 100%;
    max-width: 100%;
    padding: 30px 20px;
    box-shadow: none;
  }
._2EIVfT ._2BT2AJ {
    display: none;
  }
