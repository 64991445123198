/* Label of the field */
._2iqFLg {
  position: absolute;
  font-weight: bold;
  top: 4px;
  left: 5px;
  font-size: 12px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease;
}
/* Field container */
._3ykWq7 {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  height: 42px;
  padding-top: 18px;
  min-width: 65px;
  position: relative;
  transition: border 0.2s ease;
}
.Hr9Oge {
  padding: 0px 5px;
}
._3ykWq7._2GDmXn {
  transition: none;
  border-bottom: none !important;
}
._3ykWq7._1pnjfe {
  padding: 0;
  height: auto;
}
._3sESUm {
}
._1kLxZ0 {
}
.cKySaH {
}
._3ykWq7._5R9ocN {
  border-bottom-width: 1px;
}
.eunoNv ._2iqFLg {
    top: 12px;
    opacity: 0;
  }
._3XC8IM {
  width: 100%;
}
._7-_K-B {
}
._3ykWq7._28VMAh {
  padding: 0;
  border: 1px solid #ddd;
}
._3ykWq7._28VMAh ._2iqFLg {
    top: -18px;
  }
._3DkxkN {
}
._3ykWq7._3DkxkN ._2iqFLg {
    font-weight: normal;
  }
._3ykWq7._28VMAh._1FU-mR {
  margin-top: 10px;
}
._3ykWq7 select {
  background: white;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
          appearance: none;
  color: black;
}
._3ykWq7._2vKPIw {
  -webkit-animation: _2vKPIw 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          animation: _2vKPIw 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}
._364-NP {
}
._2BhNd1 {
}
@-webkit-keyframes _2vKPIw {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}
@keyframes _2vKPIw {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}
/* GBT specific styles */
.corp-gbt ._2iqFLg {
    color: #002663;
  }
