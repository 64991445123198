._3MOeXK {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
}
@media all and (min-width: 601px) {
._1pzNCh {
    padding: 35px 0 0 0
}
  }
.UAZWuH {
  padding: 14px 5px 0;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  font-family: inherit;
  width: 93%;
}
._353b1K {
  outline: none;
  font-size: 16px;
  font-weight: 400;
  font-family: inherit;
  text-align: left;
  padding: 5px 0px;
  display: block;
  width: 100%;
  border: none;
  box-shadow: none;
}
._1eCFqU {
  position: relative;
}
._1kph0J {
  position: absolute;
  right: 7px;
  top: 11px;
  cursor: pointer;
}
.rtl ._1kph0J {
  right: auto;
  right: initial;
  left: 7px;
}
._2Xjvx- ._353b1K {
    outline: none;
  }
._2Xjvx- ._2hsTWN:before,
  ._2Xjvx- ._2hsTWN:after {
    width: 50%;
  }
._2Xjvx- ._1aO7ga {
    -webkit-animation: _27Yghe 0.3s ease;
            animation: _27Yghe 0.3s ease;
  }
._2vO9ns {
}
._2vO9ns ._2hsTWN:before,
  ._2vO9ns ._2hsTWN:after {
    width: 50%;
  }
.nVOm9g:not(._1kYlIX) ._2iHjX3 {
  color: #444; /* value from theme.css - $SYS_FONT_COLOR */
}
._1kYlIX {
}
.nVOm9g .UAZWuH {
  opacity: 0;
}
._1kYlIX ._2iHjX3, ._2Xjvx- ._2iHjX3, .nVOm9g ._2iHjX3 {
    top: -20px;
    left: 0 !important;
    font-size: 14px;
    font-weight: 600;
    opacity: 1;
  }
._1kYlIX._21mLBP ._2iHjX3, ._2Xjvx-._21mLBP ._2iHjX3, .nVOm9g._21mLBP ._2iHjX3 {
      top: -5px;
      opacity: 0;
    }
._1kYlIX:not(._1pzNCh) .UAZWuH, ._2Xjvx-:not(._1pzNCh) .UAZWuH {
    opacity: 0;
  }
._1kYlIX:not(._1pzNCh)._21mLBP .UAZWuH, ._2Xjvx-:not(._1pzNCh)._21mLBP .UAZWuH {
      opacity: 1;
    }
._3UWlwC ._2iHjX3 {
    color: #444 !important; /* value from theme.css - $SYS_FONT_COLOR */
  }
._2KAicD {
}
._2KAicD ._2hsTWN:before,
  ._2KAicD ._2hsTWN:after {
    width: 50%;
  }
._353b1K:disabled {
  opacity: 0.6;
}
._2Xjvx- ._353b1K ._1VnPSe, ._2vO9ns ._353b1K ._1VnPSe, ._2KAicD ._353b1K ._1VnPSe {
    display: none;
  }
._1kyOr0 {
  position: relative;
}
/* Label inside the input, with animation to the top */
._2iHjX3 {
  opacity: 0.8;
  font-weight: 400;
  font-size: 16px;
  transition: 0.2s ease all;
  position: absolute;
  pointer-events: none;
  left: 100%;
  padding-left: 5px;
  white-space: nowrap;
  top: 9px;
}
._353b1K:focus ~ ._2iHjX3 {
  font-size: 16px;
  font-weight: 600;
  top: -20px;
  pointer-events: none;
}
._2hsTWN {
  position: relative;
  display: block;
  width: 100%;
}
._2hsTWN:before,
._2hsTWN:after {
  transition: 0.2s ease all;
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
}
._2hsTWN:before {
  left: 50%;
}
._2hsTWN:after {
  right: 50%;
}
._1VnPSe {
  position: relative;
  display: block;
  width: 100%;
}
._1VnPSe:before {
  left: 0;
  content: '';
  height: 1px;
  width: 100%;
  bottom: 1px;
  position: absolute;
  background-color: rgba(182, 182, 182, 0.5);
}
._1aO7ga {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
._3B9sBV._1kYlIX ._2iHjX3, ._3B9sBV._2Xjvx- ._2iHjX3 {
      transition: none;
      display: none;
    }
._3B9sBV._1kYlIX .UAZWuH, ._3B9sBV._2Xjvx- .UAZWuH {
      opacity: 1;
    }
._1GoWA7 ._353b1K, ._1GoWA7 ._1u4PI- {
    font-size: 16px;
  }
/* ANIMATIONS ================ */
@-webkit-keyframes _27Yghe {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes _27Yghe {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
.rzRvuI ._2hsTWN {
    display: none;
  }
.rzRvuI ._1aO7ga {
    display: none;
  }
.rzRvuI ._1VnPSe {
    display: none;
  }
.rzRvuI ._2iHjX3 {
    display: none;
  }
.rzRvuI .UAZWuH {
    display: block !important;
    opacity: 1 !important;
    padding-left: 0;
  }
.rzRvuI ._353b1K {
    padding: 8px 0;
  }
.rzRvuI ._3sgYuL {
    border: 1px solid rgb(222, 222, 222);
  }
/* GBT specific styles */
.corp-gbt ._1VnPSe:before {
    background-color: #4d4f53;
  }
