._1-TkBA {
  padding: 20% 20px;
  text-align: center;
}

.t8mblG {
  margin-top: 40px;
}

._2iww_A {
  width: 100%;
  margin-top: 10px;
}

._2iww_A svg {
    margin: 0 auto;
  }
