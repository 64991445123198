._2FKGaO {
  border-top: 1px solid #e6e6e6;
}

._2exxby {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  fill: green !important /* important for airportshuttles */
}

._2exxby.P6FIg9 {
    fill: orange !important; /* important for airportshuttles */
  }

._16LZk_ {
  padding: 10px 20px
}

._16LZk_.P6FIg9 {
    border-top: 1px solid #e6e6e6;
    margin-top: 10px;
  }

._16LZk_ span {
    font-size: 14px;
  }

._16LZk_ span.LKmGJ_ {
    font-size: 12px;
  }
