._2jxePN {
  padding: 30px 0;
}
._1S_ktU {
  width: 540px;
  border: 1px solid #ddd;
  padding: 0;
  margin-left: 0;
}
._3O-jcr {
  font-size: 16px;
  font-weight: bold;
  color: #428bca;
  padding: 5px 0;
}
._15wnpC {
  background-color: #f44336
}
._15wnpC:focus,
  ._15wnpC:hover {
    background: rgb(243, 42, 27);
  }
._15wnpC:active {
    background: rgb(233, 27, 12);
  }
