/* Price */
.G7KER1 {
  font-size: 16px;
}
.RQz_lw {
  font-size: 12px;
  color: red;
  text-decoration: line-through;
}
._1Q2rPY .RQz_lw,
  .G7KER1._2XIry0 .RQz_lw {
    margin-right: 5px;
  }
/* Description  */
.eJqXpN {
  color: #727272;
  margin: 0 10px 10px 10px;
}
.eJqXpN._3rSyP7 {
  margin: 0;
  text-align: left;
}
.eJqXpN._2XIry0 {
  max-width: 200px;
  margin: 0 10px;
}
._2J-19l {
  text-align: left;
}
._2J-19l ul {
    margin-left: 15px;
    list-style-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEwIDEwIj48cGF0aCBmaWxsPSIjRkZCRDA1IiBkPSJNOS43MjggMS4yMTVhLjc5NS43OTUgMCAwIDAtMS4xNTQuMDcxTDMuOTM4IDYuOTExIDEuMzI3IDQuNjU3YS43OTUuNzk1IDAgMCAwLTEuMTUuMTM3LjkzNC45MzQgMCAwIDAgLjEyOCAxLjIzN0wzLjUyNiA4LjgxYy4xNDQuMTIyLjMyNC4xOS41MDcuMTlhLjc5Ljc5IDAgMCAwIC42MTEtLjI5Nmw1LjE0OS02LjI0OWEuOTIzLjkyMyAwIDAgMCAuMjA1LS42MzguODkyLjg5MiAwIDAgMC0uMjctLjYwMnoiLz48L3N2Zz4=);
  }
/* Icon */
._3HrC0h {
  font-size: 40px;
  line-height: 40px;
}
._3HrC0h,
  ._3HrC0h svg {
    width: 40px;
    height: 40px;
  }
._3HrC0h g, ._3HrC0h svg g {
      fill: #428bca !important;
    }
._2JV68D {
  margin-top: -5px;
  margin-right: 10px;
}
._3xvZV0 svg g {
  fill: #58b76a !important;
}
/* Add to trip button / 'added' notice */
._2_FDtn {
  margin: 0 auto;
  padding: 10px 15px;
  white-space: nowrap;
}
._1uwz8j {
  background-color: #58b76a !important;
  color: #ffffff !important;
}
._3ha8cO {
  font-size: 16px;
}
._2zDy13 {
  margin-left: 5px;
  width: 18px;
  height: 18px;
  line-height: 18px;
}
._2zDy13 svg {
    width: 18px;
    height: 18px;
  }
/* GBT specific styles */
.corp-gbt ._3HrC0h,
    .corp-gbt ._3HrC0h svg {
      fill: #002663;
      color: #002663;
    }
.corp-gbt ._3HrC0h g, .corp-gbt ._3HrC0h svg g {
        fill: #002663;
      }
