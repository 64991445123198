.JqG4-O {
  border-radius: 0 0 3px 3px;
}
._1g9UVI {
  overflow: hidden;
  border-radius: 3px 3px 0 0;
  position: relative;
}
._1xjGa5 {
  border-bottom: 1px solid #eeeeee;
}
._1Jh0fJ {
}
.xhFCWi {
}
.R5zHNk {
}
.R5zHNk .Y9fUDj {
    text-transform: none;
    padding: 6px 35px;
  }
.R5zHNk ._1g9UVI {
    border-radius: 0;
  }
.Y9fUDj {
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  letter-spacing: 2px;
  position: relative;
  padding: 15px 35px 15px 50px;
  transition: all 0.2s ease-in-out
}
.Y9fUDj:after {
    transition: opacity 0.2s ease-in-out;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
  }
@media all and (max-width: 600px) {
.Y9fUDj {
    padding: 15px 30px 15px 40px
}
  }
._2nGfB7 {
  transition: color 0.2s ease-in-out;
  position: absolute;
  top: 12px;
  left: 20px;
  font-size: 22px
}
@media all and (max-width: 600px) {
._2nGfB7 {
    left: 15px
}
  }
._2PDirf {
}
.rtl ._2nGfB7 {
  left: auto;
  left: initial;
  right: 20px;
}
.rtl ._1rDCVz {
  padding: 15px 50px 15px 35px;
}
