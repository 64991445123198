._3qVNPt {
  background-color: white;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
  overflow: hidden;
}
._3qVNPt:first-child {
  border-top: 1px solid #e4e4e4;
}
._2io9Jd {
}
._2io9Jd span {
    padding-left: 5px;
  }
.IRQNbC g, .IRQNbC svg g {
      fill: #428bca;
    }
.coYwwp {
  background-color: #f9f9f9;
}
._3gO25u {
  border-bottom: 0;
}
._3gO25u ._1TvJgd {
    margin: 10px 0;
  }
._3gO25u ._3nwmod {
    margin: 10px 10px 20px 0;
  }
._2afDao {
  padding: 2px 0;
}
/* start-of: details container */
._1TvJgd {
  margin: 20px 0;
}
._3nwmod {
  margin: 20px 0 20px 0;
  font-size: 14px;
}
._2CPVgu {
  font-size: 12px;
}
._1CDEr8 {
  position: relative;
  height: 40px;
}
.sTqBAf {
  line-height: 26px;
  max-width: 0px;
  min-width: 100%;
}
.sTqBAf svg {
    width: 10px;
    min-width: 10px;
    margin-right: 5px;
  }
._2HHHo8 {
  background-color: transparent
}
._2HHHo8:after {
    content: '•';
    padding: 0 8px;
  }
._2Ojstq {
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
._2Ojstq span {
    padding-right: 10px;
  }
._2Ojstq span._2HHHo8 {
    padding-right: 0;
  }
._3ln-7V {
  margin-top: 5px;
  max-width: 0px;
  min-width: 100%;
}
.x9PXKy {
  font-weight: 400;
  font-size: 14px;
  margin-top: -1px;
  color: grey;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
._1zI4a5 {
  color: grey;
}
._2UDeLs {
  margin-top: 5px;
}
.wEe2Wj {
  padding-right: 10px
}
.wEe2Wj > div {
    padding: 0;
  }
.wEe2Wj > span {
    white-space: nowrap;
  }
._11iIjn {
  text-decoration: underline;
  padding: 7px 0;
  margin: -7px 0;
}
._3cOIyl {
  margin-top: 5px
}
._3cOIyl > span {
    white-space: nowrap;
  }
._2C1wAP {
  margin-top: 5px;
  color: #c3c3c3;
  font-size: 14px;
}
._2-xiqU {
  margin-right: 10px;
  white-space: nowrap;
}
._3sFms4 {
  margin-top: 5px;
}
._2Wfeos {
  margin-left: 0 !important;
  fill: #4287c6;
}
._1BfhR- {
  margin-top: -5px;
}
._1ovFXD {
  padding-right: 10px;
}
._1ovFXD svg {
    margin-right: 5px;
    fill: #c3c3c3;
  }
._1ovFXD._2l9XCZ svg {
      height: 14px;
      width: auto;
      fill: #4287c6;
    }
/* end-of: details container */
/* start-of: price column */
._3sihlB {
  padding: 20px 10px 20px 0;
}
.SOpKzD {
  font-size: 12px;
  padding-top: 3px;
}
._37ZLnb {
  font-size: 26px;
  line-height: 26px;
}
.nmyp0g {
  font-size: 12px;
  line-height: 18px;
}
._1LLcVJ {
  font-size: 14px;
  line-height: 18px;
  margin-right: 10px;
  color: red;
  text-decoration: line-through;
}
/* end-of: price column */
/* GBT specific styles */
.corp-gbt .WsKwS7 {
    background: #002663;
  }
