.xFoGI_ {
  padding: 100px;
  background-color: #428bca;
}
._3t9_pb {
  padding: 0;
  border-bottom: 1px solid #ddd;
}
.corp-renault .xFoGI_ {
    background-color: #ffffff;
  }
