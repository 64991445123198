/* Basic uncolored Button styles */
._2W7kc0 {
  position: relative;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  outline: 0;
  border: 0;
  margin: 0;
  font-weight: 400;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.2s ease-in;
  white-space: normal;
  overflow: hidden;
  padding: 8px 25px 8px 25px;
  font-size: 18px;
}
._2W7kc0:after {
  position: absolute;
  content: 'keyboard_arrow_right';
  font-size: 20px;
  text-rendering: auto;
  margin-left: 5px;
  transition: all 0.3s;
  right: 10%;
  opacity: 0;
  top: 11px;
}
._2W7kc0:hover:after {
  right: 5%;
  opacity: 1;
}
._2W7kc0._3NspdR,
._2W7kc0:disabled {
  opacity: 0.5;
  pointer-events: none;
  text-shadow: none;
}
._3NspdR {
}
.rtl ._2W7kc0:after {
    right: auto;
    right: initial;
    left: 10%;
    content: 'keyboard_arrow_left';
  }
.rtl ._2W7kc0:hover:after {
    right: auto;
    right: initial;
    left: 5%;
  }
/* Button colors */
._1DCWIx {
  border-radius: 3px;
}
._3kgFjJ {
}
._20Hql7 {
}
._3tmBn4 {
}
._3nriJj {
}
