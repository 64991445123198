.K7pI8_ {
  padding: 20px;
  color: black;
}

.fo34K8 {
  color: black;
  font-size: 45px;
  font-weight: bold;
}

._2xYGeb {
  margin-top: 20px;
}

.G3sx_Z {
  font-size: 36px;
  color: black;
}
