/* Basic style */
._1NFB0N {
  text-decoration: none;
  cursor: pointer
}
._1NFB0N:hover {
    text-decoration: underline;
  }
/* Coloring */
.weib-d {
    color: #428bca;
    transition: all 0.3s ease
  }
.weib-d:hover {
      color: rgb(48, 114, 171);
    }
.weib-d:active {
      color: rgb(37, 87, 131);
    }
.M2Y-nx {
    color: #727272;
    transition: all 0.3s ease
  }
.M2Y-nx:hover {
      color: rgb(89, 89, 89);
    }
.M2Y-nx:active {
      color: rgb(64, 64, 64);
    }
/* Modifiers */
._2rNkiK {
  text-transform: uppercase;
}
