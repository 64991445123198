._1TrVhy {
  display: table;
  width: 100%;
  max-width: 600px;
  margin: -15px 0;
}
._2D9Cgv,
._3C9S29 {
  display: table-cell;
  vertical-align: middle;
}
._2D9Cgv {
  position: relative;
  width: 90px;
  padding-top: 15px
}
._2D9Cgv:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    background-color: #4d4f53;
  }
._3C9S29 {
  position: relative;
  padding: 65px 0 80px
}
._3C9S29:before,
  ._3C9S29:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #adafaf;
    opacity: 0.5;
  }
._3C9S29:before {
    top: 40px;
  }
._3C9S29:after {
    bottom: 40px;
  }
._1zBFhq {
  position: relative;
  width: 24px;
  height: 24px;
  margin: auto;
  border: 1px solid #4d4f53;
  border-radius: 24px;
  background-color: #ffffff;
}
._1zBFhq i {
    margin: 3px 4px;
    font-size: 15px;
  }
._1zBFhq svg {
    margin: 6px;
  }
.Myyiw1 {
  width: 10px;
  height: 10px;
  color: #4d4f53;
  fill: #4d4f53;
}
._3xsUYF,
._161SIf {
  color: #8b8d8e;
  font-size: 12px;
  font-weight: 100;
}
._3xsUYF {
  text-transform: uppercase;
}
._3RN1Ml {
  margin-top: 15px;
  font-size: 16px;
  color: #4d4f53;
}
.R_UWE6 ._2D9Cgv {
      width: 60px;
    }
