._3M_Scl {
  padding: 100px;
  background-color: #428bca;
}
.SljFZz {
  max-width: 50%;
  min-width: 380px;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 2px 2px 10px -2px #000000;
  text-align: center;
}
.EvW7Di {
  margin: 0 auto;
  float: none;
}
.EvW7Di img {
    height: 48px !important;
    width: 120px;
  }
._3cQFWX {
  margin: 40px 0 10px;
  padding: 0 35px;
  font-size: 20px;
}
._3QRcX_ {
  padding: 0;
  border-bottom: 1px solid #ddd;
}
._3QRcX_ .SljFZz {
    min-width: 100%;
    max-width: 100%;
    padding: 30px 20px;
    box-shadow: none;
  }
.corp-renault ._3M_Scl {
    background-color: #ffffff;
  }
