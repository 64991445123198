._18KLBO {
  margin: 10px 20px 0 0;
  height: 22px;
  cursor: pointer;
}
._18KLBO._12FvI0 {
  margin: 10px 0 0 0;
  padding-bottom: 25px;
}
._3lZzC3 {
}
