.Nrxxky {
  position: relative;
  width: 100%;
  display: table;
  table-layout: fixed;
  padding: 25px 0 40px;
}

  .Nrxxky > div {
    display: table-cell;
    vertical-align: middle;
  }
.Nrxxky:first-child {
    border-bottom: 1px solid #adafaf;
  }
.VXGCG- {
  position: relative;
  text-align: center;
  width: 80px
}
.VXGCG-:before,
  .VXGCG-:after {
    content: '';
    position: absolute;
    left: 50%;
    width: 1px;
    height: 15px;
    background-color: #002663;
  }
.VXGCG-:before {
    top: calc(50% - 13px);
  }
.VXGCG-:after {
    top: calc(50% + 22px);
  }
._1eQPRX {
  font-size: 16px;
  color: #002663;
  padding-top: 30px;
}
._3nsMle .VXGCG- {
    width: 40px;
  }
