._8S4bDx {
  top: -9999px;
  left: -9999px;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  -ms-touch-action: pan-y;
}

._2IhdOD {
  top: 0px;
  left: 0px;
}
