._3cgjoZ {
  padding: 0;
}
.LQhHlB {
  height: 420px;
}
._7nj9cH {
  font-size: 34px;
  line-height: 60px;
  font-weight: bold;
  color: #ffffff;
}
._2zr_kA {
  display: block;
}
._2QNBqs {
  margin-top: 38px;
}
._2jY1zy {
  min-width: 200px;
  margin: 0 20px 20px;
  font-size: 20px;
}
._1Xds-w {
  padding: 9px 25px;
  min-width: 200px;
  margin: 0 20px 20px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid #ffffff;
  display: inline-block;
  transition: background-color 0.2s ease
}
._1Xds-w._3lEVBQ {
    background-color: transparent
  }
._1Xds-w._3lEVBQ:hover {
      background-color: #315375;
    }
._1Xds-w._2zr_kA {
    background-color: #ffffff
  }
._1Xds-w._2zr_kA:hover {
      background-color: #eee;
    }
