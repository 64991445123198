._2l27UK {
  min-width: 320px;
  max-width: 80%;
  padding: 40px 30px;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 2px 2px 10px -2px #000000;
}
._2G4TZD {
  min-width: 100%;
  max-width: 100%;
  padding: 30px 20px;
  box-shadow: none;
}
._3VnKoC {
  height: auto;
  margin-right: 15px;
}
._3VnKoC img {
    height: 48px !important;
    width: 120px;
  }
.QWP28P {
  font-size: 24px;
  color: #000000;
  padding-top: 10px;
}
.Difn7h {
  padding: 10px;
}
._3A7V0a {
  padding: 20px 10px 30px;
}
._2ItEIf {
  margin-right: 20px;
  line-height: 25px;
}
._13jSE_ {
  min-width: 300px;
  border-radius: 3px;
}
.pGJIGG {
}
.Tiv0TM {
}
