.NzWSpG {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}

._2T5Fnv {
  padding: 6px 10px;
}

._3nn1fP {
  float: right;
}

._3JzyCF {
  float: left;
}

.GI94BR {
}

._2ZL5ws {
  background-color: white;
}

.Y7Ga14 {
}

._1InURL {
}

._3R894d {
}

.cYCK5i {
}

._3dNv6l {
  padding-top: 0;
  margin-right: 10px;
  margin-left: 10px;
  border-bottom: 0;
}

._2LPk9H {
  min-width: 15px;
  height: 15px;
  margin-right: 5px;
}
