._21VrJm {
  width: 100%;
  padding: 20px 0;
  background-color: white;
  border: 1px solid rgb(239, 239, 239);
}
._3OlBuq {
}
._1TB_QI {
  position: relative;
}
.pnPyYn {
}
.pnPyYn svg {
    margin-right: 5px;
  }
._2NAq2J {
  padding: 0 10px 30px 10px;
  border-bottom: 1px solid #e4e4e4;
}
/* start-of: picture column */
._1dXfI5 {
  padding: 0 5px;
  min-width: 65px;
}
.W40vkR {
  width: 40px;
  height: 40px;
  border: 2px solid #e4e4e4;
  background: #ffffff;
}
._3-XPzO {
  margin-top: 5px;
}
/* star size */
._3-XPzO div span i {
    font-size: 10px;
  }
/* end-of: picture column */
/* start-of: details container */
._2cult4 {
  padding: 20px 10px 20px 0px;
  border-bottom: 1px solid #e4e4e4;
}
._2YoN9r {
  bottom: -2px;
  right: -2px;
  background: #b7b7b7;
}
._12Bz1A {
  font-size: 14px;
}
._1K_kO- {
  margin-top: 15px;
}
._1gqq_f {
}
._3eT6kt {
  font-weight: 400;
  font-size: 19px;
  line-height: 19px;
}
._3k0eei {
  display: block;
  font-size: 12px;
  margin-top: 8px;
}
._3k0eei span {
    margin-left: 5px;
  }
._1zopZV {
  margin-bottom: 10px;
}
._2QhrqO {
  margin-bottom: 10px
}
._2QhrqO > * {
    margin-right: 5px;
  }
._196OtW {
  margin-left: 0 !important;
  fill: #4287c6;
}
._1ExuQY {
  display: inline-block;
  border-radius: 50%;
  padding: 2px 2px 1px 2px;
  font-size: 6px;
  margin: 0 8px;
  font-weight: 700;
  border-width: 1px;
  cursor: normal;
}
._1V9zgJ {
  cursor: pointer;
  font-size: 12px;
}
.zMK_tN {
  margin-bottom: 10px;
}
._2HHzq8 {
  padding-right: 10px;
  font-size: 14px;
}
._2HHzq8 svg {
    margin-right: 5px;
  }
._2HHzq8._1wrsVU svg {
      height: 14px;
      width: auto;
      fill: #4287c6;
    }
.dL7ZWa {
  margin-top: 8px;
}
/* end-of: details container */
/* start-of: trip mode container */
._1rf8ti {
}
/* end-of: trip mode container */
.Rr1AUG {
  margin-left: 10px;
}
/* start-of: price column */
._3B74Pi {
  font-size: 12px;
  padding: 0 10px;
  margin-bottom: 10px;
  margin-top: 20px;
}
._3UOoej {
  color: red;
  padding: 10px 10px 0;
  text-decoration: line-through;
}
.zqZ3y0 {
  margin-right: 3px;
}
._14iCJy {
  font-weight: 400;
  font-size: 28px;
  margin-bottom: -8px;
}
._27oDaS {
  color: #c3c3c3;
}
/* end-of: price column */
.twOixy {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}
.RHoCy0 {
  font-size: 12px;
  margin-right: 2px;
}
.uAdJOt {
}
/* Data Label */
._31frpk {
}
._2Ll4a9 {
}
._1DKArW._1gPU5l {
  margin-left: 0;
}
._1DKArW._1gPU5l g, ._1DKArW._1gPU5l svg g {
      fill: #428bca;
    }
.YtlXb3 {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 1px;
}
._3-LAaD {
  position: -webkit-sticky;
  position: sticky;
  bottom: 20px;
  margin: auto;
}
