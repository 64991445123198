._2wAuEt {
  position: relative;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
.tdBrfX {
  text-align: center;
  padding: 36px 20px;
  background-color: #006890;
}
._3yTfYX {
  background-color: #006890;
}
.G8zm2w {
  font-size: 32px;
  color: #ffffff;
}
._6qZnZW {
  position: relative;
  width: 100%;
  height: 360px;
  overflow: hidden;
  cursor: pointer
}
._6qZnZW:hover ._1PFdKZ {
      -webkit-transform: scale(1.02);
              transform: scale(1.02);
    }
._1k9miT {
  max-width: 1500px;
  margin: 0 auto;
  font-size: 18px;
  padding: 20px 20px 10px;
  color: #ffffff;
}
._1PFdKZ {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease
}
._1PFdKZ:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #006890;
    opacity: 0.8;
  }
.jFKb5P {
  height: 360px;
  min-width: 100%;
  object-fit: cover;
}
._2GTrMw {
  position: absolute;
  right: 0;
  left: 0;
  max-width: 1500px;
  margin: 0 auto;
  text-align: right;
  font-size: 18px;
  padding: 35px 20px 0 0;
  color: #ffffff;
}
._1FpEr2 {
  position: absolute;
  display: table;
  height: 100%;
  width: 100%;
}
.OcXcdB {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
._1Axuyd {
  text-transform: uppercase;
  font-size: 18px;
  color: #ffffff;
}
.cOMnB5 {
  font-size: 40px;
  color: #ffffff;
}
.Vj8mgS .tdBrfX {
    padding: 20px;
  }
.Vj8mgS ._6qZnZW {
    height: 260px;
  }
.mobile ._2vmErG {
    min-height: 795px;
  }
._2vmErG {
  min-height: 670px;
}
._261Mz6 {
  font-size: 24px;
  font-weight: 100;
  color: #ffffff;
}
.gu84jv {
  position: relative;
  padding: 10px 80px;
  margin-top: 40px;
}
._38POVj {
  position: absolute;
  left: 20px;
}
