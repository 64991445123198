._37toVP {
  width: 260px;
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #fff;
  color: #444;
  padding: 10px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);
  font-weight: normal;
  z-index: 1
}

._37toVP:after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    top: -10px;
    right: 40px;
    border-bottom: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }

._2ePDxu {
  text-align: right;
  color: #b2b2b2;
  text-decoration: underline;
  cursor: pointer;
}
