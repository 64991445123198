._1-rQUz {
  background: #eeeeee;
  padding: 30px 10px
}
@media all and (min-width: 601px) {
._1-rQUz {
    padding: 0 30px
}
  }
._3QDSli {
  height: 400px;
}
._b6aaV {
  max-height: 400px;
}
._3w5xWX {
  color: black;
  font-size: 48px;
  font-weight: 400;
  line-height: 48px;
}
._3DWDDn {
  font-size: 28px;
  font-weight: 300;
}
.JJ25Nz {
  font-size: 18px;
  color: #727272;
}
._15UE3y {
  background-image: url(/3cb5fd1ce6d7510c8447b544b32a1f00.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
  max-width: 600px;
}
._1hVMtT {
}
@media all and (max-width: 599px) {
._1hVMtT {
    width: 100%
}
  }
@media all and (max-width: 599px) {
._13-4vS {
    text-align: center;
    width: 100%
}
  }
