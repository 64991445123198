._2DjneK {
  padding: 100px;
  background-color: #428bca;
}
._2rRwms {
  max-width: 40%;
  min-width: 380px;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 2px 2px 10px -2px #000000;
}
._3dMYJM {
  margin: 0 auto;
  width: 100%;
}
._3dMYJM img {
    height: 48px !important;
    width: 120px;
  }
._1UNYYz {
  font-size: 24px;
  color: #000000;
  margin: 15px auto;
}
._2_cupy {
  margin-bottom: 20px;
}
.dcbSI2 {
  width: 100%;
  margin: 20px 0 25px;
  border-radius: 3px;
}
._3RsguT {
}
._20CmvV {
  padding: 0;
  border-bottom: 1px solid #ddd;
}
._20CmvV ._2rRwms {
    min-width: 100%;
    max-width: 100%;
    padding: 30px 20px;
    box-shadow: none;
  }
.corp-renault ._2DjneK {
    background-color: #ffffff;
  }
