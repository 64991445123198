._2dGHxz {
}
._2BHjEb {
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  color: #444;
  width: 100%;
  padding-right: 10px;
  padding-left: 5px;
  font-size: 14px;
}
._1oGreD {
  padding-left: 5px;
}
._2-PGqF {
  position: relative;
  height: 24px;
}
._5Sqy3f {
  min-width: 0;
  margin-left: 5px;
}
._2wE5gg {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
._2wE5gg,
.qCX9aP {
  font-size: 18px;
}
.qCX9aP {
  white-space: nowrap;
  text-align: right;
  margin-bottom: 5px;
}
._1YTi4l {
}
._1AEhS6 {
  color: #b6b6b6;
  text-align: left;
}
.WTZA8H {
  background-color: transparent
}
.WTZA8H:after {
    content: '•';
    padding: 0 8px;
  }
._3qWbX0 {
  margin-right: 10px;
}
._1lCdns {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  border-color: #777;
  color: #777;
  padding: 7px
}
._1lCdns:hover,
  ._1lCdns:focus {
    border-color: #555;
    color: #555;
  }
.mobile ._3vAc5Y {
    border-top: 1px solid #e6e6e6;
  }
/* GBT specific styles */
.corp-gbt ._2dGHxz {
    background-color: transparent;
  }
