._2X1f_K {
  margin-top: 40px;
  min-width: 320px;
  max-width: 960px;
  padding: 30px;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 2px 2px 10px -2px #000000;
  border: transparent;
  border-radius: 4px;
  width: 100%;
}
._2tOuz4 {
  min-width: 100%;
  max-width: 100%;
  padding: 40px;
  box-shadow: none;
}
.ZDKVuv {
  padding: 10px;
  margin-bottom: 20px
}
.ZDKVuv:last-child {
    margin-bottom: 0;
  }
.CELP2J {
  padding: 0 10px 30px;
}
._3jZXNC {
}
._21u-CU {
  margin-right: 10px;
}
