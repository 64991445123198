.lCvcXT {
  position: relative;
}
._1zfGmo {
  text-transform: uppercase;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 10px;
}
._1zfGmo span {
    margin-left: 5px;
  }
._15fPFl {
}
/* GBT specific styles */
@media all and (max-width: 600px) {
      .corp-gbt ._2T3K4Z > div {
        display: block;
      }
        .corp-gbt ._2T3K4Z > div:first-child div {
          font-size: 12px;
          padding: 15px;
          max-width: 50%;
          display: inline-block;
        }
  }
