._15F9De {
  margin-left: 5px;
}

._2GWk9u {
}

._32wvpB {
  margin-bottom: 20px;
}

._2hZtb6 {
  margin: 10px 10px 10px 0;
}

._1pKMBA {
  margin-left: 10px;
}
