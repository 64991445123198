._3Il76y {
  background-color: white;
  overflow: hidden;
}
.K4EgJh {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px,
    rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  border-radius: 0px;
  position: relative;
  z-index: 1100;
  padding: 10px;
  border-bottom: 1px solid #b6b6b6;
}
._2vo12c {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  margin-right: 10px;
}
.ZcRPe5 {
  font-size: 12px;
  font-weight: bold;
}
.U0D7zh {
  font-size: 24px;
}
._7x9H8R {
  cursor: pointer;
}
._1V8Rkz {
  font-size: 18px;
  margin-right: 5px;
}
._1V2A0X {
  margin-right: 10px;
}
._3_dHsf {
  overflow: hidden;
}
.vl7QHn {
  font-size: 12px;
  font-weight: 500;
}
.g4Kk2X {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
._1UK8cV {
  border-bottom: 1px solid #eee;
}
._1Wq-5W {
  max-height: 80px
}
._1Wq-5W:nth-child(even) {
    background-color: #eee;
  }
._1Wq-5W:hover,
  ._1Wq-5W:nth-child(even):hover {
    background-color: rgba(66, 135, 198, 0.1);
  }
._2sb_qd {
  margin: 20px;
}
._2RdY8y span {
    font-size: 14px;
    font-weight: 500;
  }
._2RdY8y {
  background-color: #eee;
}
.QOAcB3 {
  padding: 10px 0;
  text-align: center;
}
.QOAcB3 span {
    font-size: 16px;
    font-weight: regular;
  }
._15BLec {
  font-size: 12px;
  font-weight: 500;
  width: 85px;
  text-align: center;
}
._2BcXTx {
  width: 85px;
  text-align: center;
  position: relative;
}
.XQSLVq {
  background-color: #428bca;
  color: #ffffff;
  font-weight: 500;
}
.bRt0Yt {
  width: 95px;
}
._1YImh8 {
  height: 70px;
  text-align: center;
}
._1YImh8 span {
    display: block;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
._29YBzq {
  text-align: center;
  min-width: 100px;
}
._29YBzq label {
    display: block;
    height: 85px;
    cursor: pointer;
  }
._30-Z-S {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  white-space: nowrap;
}
._1gM_WH {
  font-size: 12px;
  opacity: 0.5;
}
.L30GPQ {
  margin-left: 10px;
}
._3Bz4bb {
  overflow-y: auto;
  min-height: 300px;
  max-height: 300px;
}
._2Dh2j6 svg {
    width: 25px;
    margin-right: 10px;
  }
._1CSh4a {
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
}
.dGs96s {
  margin: auto;
  width: 90px;
}
.R9r2O- {
  margin: auto;
  font-size: 18px;
}
._1m6KW6 {
  width: 22px;
  position: absolute;
  fill: #4287c6;
  left: 74px;
  top: -2px;
}
