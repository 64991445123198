._2r8uY0 {
  position: relative;
  padding: 5% 2.5% 5% 2.5%;
  padding: 25px
}
@media all and (max-width: 960px) {
._2r8uY0 {
    padding: 15px 10px
}
  }
._2vIfGG {
  position: relative;
  max-width: 750px;
  margin: 0 auto;
}
._2vIfGG h2 {
    margin: 0 0 45px;
  }
@media all and (max-width: 960px) {
._2vIfGG {
    text-align: center
}
  }
._27yMD4 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
  background-image: url(/4e7e158a9b804598a42059208fb5b25c.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover
}
._27yMD4:before {
    background: rgba(51,51,51,0.3);
  }
@media all and (max-width: 1280px) {
._27yMD4 {
    background-size: 1280px auto
}
  }
