._3ulNI3 {
  cursor: default;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
._1FmJZJ {
}
._2kLvkg {
  color: #e8e8e8;
  transition: color 0.2s;
  font-size: 16px;
}
.cYMf5Z {
  color: #fcc710;
}
._1mtIRw {
  /* TODO: Define a final color for hover state. */
  color: #1045fc;
  cursor: pointer;
  transition: color 0.5s;
}
._10mBeP {
  color: #fcd242;
  transition: color 0.1s;
}
/* GBT specific styles */
.corp-gbt .cYMf5Z {
    color: #009bbb;
  }
.corp-gbt ._1mtIRw {
    color: #002663;
  }
.corp-gbt ._10mBeP {
    color: #00bfe6;
  }
