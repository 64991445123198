._31_rW1 {
  background-color: white;
  padding-right: 10px;
}

._2KursN {
  white-space: nowrap;
  margin-top: 15px;
  margin-left: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.dPC6PZ {
  display: none;
}

._2p3fPS {
  padding-right: 0;
}

._2p3fPS ._2KursN {
    margin: 10px;
  }

._2p3fPS .dPC6PZ {
    width: 100%;
    display: block;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 10px;
  }
