._1TzzuY {
  background-color: #f8f8f8;
  border: 1px solid #e8e8e8;
  padding: 20px 10px 20px 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 3px;
}

._2aA32F {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  padding-right: 10px;
}

._1XSoID {
  font-size: 14px;
  color: #000;
}
