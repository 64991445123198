._2LezX5 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row
}
@media all and (max-width: 600px) {
._2LezX5 {
    -webkit-flex-direction: column;
            flex-direction: column
}
  }
._2IUR6D {
  float: left
}
@media all and (max-width: 600px) {
._2IUR6D {
    float: none
}
  }
._2tc_n3 {
  pointer-events: none;
}
