.vnC4j_ {
  position: relative;
}

._22Oicv {
  position: absolute;
  width: 63px !important;
  height: 84px !important;
  top: -82px;
  left: -31px;
}

.dD_l8f {
  width: 53px !important;
  height: 53px !important;
  position: absolute;
  top: -77px;
  left: -26px;

  -webkit-animation: RqjZDH linear 2s;

          animation: RqjZDH linear 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

._2jSlWI {
  position: absolute;
  text-align: center;
  top: -66px;
  left: -11px;
  color: #f0f0f0;
  z-index: 100;
  font-size: 14px;
  line-height: 14px;
}

._1gafB6 {
  position: absolute;
  width: 15px !important;
  height: 32px !important;
  top: -67px;
  left: -9px;
  z-index: 100;
}

@-webkit-keyframes RqjZDH {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes RqjZDH {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
