.yE17fo {
  color: #444;
  background: #eee;
}
._5mN1Oy {
  max-width: 620px;
  width: 100%;
  margin: 20px auto 0 auto;
  text-align: center;
}
@media all and (max-width: 959px) {
  ._5mN1Oy {
    width: 100%;
    padding: 0 5px;
  }
}
@media all and (min-width: 960px) {
  ._5mN1Oy {
    width: 620px;
  }
}
._1Sl8hX {
  background: #f8f8f8;
  padding: 30px;
}
._2uOI5c {
  margin: 10px auto 30px auto;
}
._2UbEw9 {
  border: 1px solid #ddd;
  min-height: 100px;
}
/* Star effects, copy-pasted from legacy */
._26lsyz {
  margin: 30px auto;
  min-height: 2em;
  width: auto;
  font-size: 24px;
  display: inline-block;
}
._26lsyz:not(:checked) > input {
  position: absolute;
  top: -9999px;
  clip: rect(0, 0, 0, 0);
}
._26lsyz:not(:checked) > label {
  float: right;
  width: 1em;
  padding: 0 0.5%;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 200%;
  line-height: 1.2;
  color: #ddd;
}
._26lsyz:not(:checked) > label:before {
  transition: color 0.3s;
}
._26lsyz > input:checked ~ label {
  color: gold;
}
._26lsyz:not(:checked) > label:hover,
._26lsyz:not(:checked) > label:hover ~ label {
  color: gold;
}
._26lsyz > input:checked + label:hover,
._26lsyz > input:checked + label:hover ~ label,
._26lsyz > input:checked ~ label:hover,
._26lsyz > input:checked ~ label:hover ~ label,
._26lsyz > label:hover ~ input:checked ~ label {
  color: #ffc107;
}
._26lsyz > label:active {
  position: relative;
  top: 2px;
  left: 2px;
}
._2sTGKC {
  display: none;
}
._1PNLti {
  margin-bottom: 20px;
}
._3w0PQo {
  margin: 40px 0;
}
