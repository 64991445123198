._1Mr609 {
}
.ZVm6Fa {
  margin: 20px 20px 10px 20px;
}
._3NnT7r {
  font-size: 18px !important;
  border-color: #e6e6e6;
}
@media all and (max-width: 1280px) {
  ._1Mr609 {
    margin-right: 0;
  }
  ._3NnT7r {
    width: 100%;
  }
}
._2QyPX- {
  font-size: 14px;
  margin-right: 2px;
  white-space: nowrap;
}
._2QyPX-:after {
    content: ':';
  }
._2QyPX- {
}
._2nGcrP {
  white-space: nowrap;
  font-size: 14px;
}
.tUIdcu {
  font-size: 14px;
  color: #3f9952;
}
._2lQWJO,
._1B8P4Z {
  width: 12px;
  margin-right: 20px;
  fill: #428bca;
}
._1B8P4Z {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
._3NxSUE {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  margin-bottom: 10px;
}
._1J7JLu {
  height: 30px;
}
._1oZ9kO {
  width: 18px;
}
