._3Th6mH {
  min-height: 40px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
}

._1Ji5gy {
  position: relative;
}

._3aDhYz {
  padding: 10px 0;
  color: #444;
  font-size: 14px;
  white-space: nowrap;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  min-width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.BQiMTa {
  min-width: 65px;
  height: 40px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
}

._2P-zBz,
._1WMvPn {
  padding: 10px 20px;
}

._2wgRuc {
  color: #b2b2b2;
}

._1fyLsX {
  cursor: pointer;
  margin-right: 10px;
}

.SSiblW {
  background-color: #fff;
  border-bottom-width: 1px;
  height: 40px !important;
}
