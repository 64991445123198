._3ffdeu {
  background-color: white;
  border-bottom: 1px solid #eee;
  padding: 0;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto; /* safari fix */
}
._1KcevI {
  opacity: 0.5;
}
._2iN5fe {
  margin-bottom: 20px;
}
._2iN5fe label {
    font-size: 12px;
  }
._38H-WM {
  margin: -5px 0 20px -5px;
}
._2HULpt {
  padding-left: 20px
}
@media (max-width: 600px) {
._2HULpt {
    padding-left: 10px
}
  }
._2wRVlI {
  padding-right: 20px
}
@media (max-width: 600px) {
._2wRVlI {
    padding-right: 10px
}
  }
._1ifdpA {
  position: relative;
  max-width: 20px;
  min-width: 20px;
}
._12BwAm {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #eee;
  z-index: 1;
}
._29HzTU {
  position: absolute;
  margin: auto;
  left: -1px;
  top: 0;
  bottom: 0;
  z-index: 2;
}
._2UdCtG ._2-zyNV {
    color: #428bca;
    padding: 0 10px;
  }
._3ryQvP {
}
._34TrES > div:first-child {
  margin-right: 10px;
}
._3oPene {
  font-weight: 500;
}
._3BrYCd {
  font-weight: 500;
}
._2izBQy {
  padding: 2px 0px;
}
/* GBT specific styles */
.corp-gbt ._38H-WM {
    color: #4d4f53;
  }
