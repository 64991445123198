.IHSHS0 {
  margin-top: 80px;
  min-width: 320px;
  max-width: 400px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 2px 2px 10px -2px #000000;
  position: relative;
  border: transparent;
  border-radius: 4px;
}
.Sfkt9b {
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 18px;
  color: #002664;
}
._3xWQJs {
  border: 1px solid #ffffff;
  border-radius: 50%;
  background-color: #002664;
  width: 60px;
  height: 60px;
  color: #ffffff;
  position: absolute;
  top: -30px;
  left: 50%;
  margin-left: -30px;
}
._3wtRD3 {
  fill: #ffffff;
  color: #ffffff;
}
