.k_NQHg {
  max-width: 50%;
  min-width: 380px;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 2px 2px 10px -2px #000000;
  text-align: center;
}
.f1IZeY {
  min-width: 100%;
  max-width: 100%;
  padding: 20px;
  box-shadow: none;
}
._3yIR_6 {
  font-size: 18px;
  color: #002664;
}
