.Ssgsew {
  display: inline-block;
  text-align: center;
}
.AlYSyF {
  background-color: transparent
}
.AlYSyF:after {
    content: '•';
    padding: 0 8px;
  }
