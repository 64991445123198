/* Block */
._3qyUSC {
}
.dRjtnr {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 1;
}
.tiiPc2 {
  position: relative;
  z-index: 2;
  min-width: 500px
}
@media all and (max-width: 499px) {
.tiiPc2 {
    min-width: 0;
    width: 100%
}
  }
._38deLx {
  padding: 30px;
  background: #fff;
}
._3o4BqQ {
  position: relative;
}
._2Gm-mm {
  font-size: 30px;
  margin-bottom: 20px;
  color: #000;
  text-align: center;
  position: relative;
  z-index: 1;
}
._30x_Wh {
  position: absolute;
  background: transparent;
  z-index: 2;
  top: -30px;
  right: -30px;
}
/* Modifiers */
._16sF83 ._38deLx {
    padding: 0;
  }
._16sF83 ._3o4BqQ {
    display: none;
  }
._2UpBSF .tiiPc2 {
    min-width: 700px
  }
@media all and (max-width: 699px) {
  ._2UpBSF .tiiPc2 {
      min-width: 0;
      width: 100%
  }
    }
._2-7Igd .tiiPc2 {
    border-radius: 3px;
    overflow: hidden;
  }
._2eI80l .tiiPc2 {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.45);
  }
._3j5Ep4 ._38deLx {
    padding: 0;
  }
._1E_fau {
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px,
    rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  padding-left: 20px;
  padding-right: 5px;
  position: relative;
}
._1E_fau ._2Gm-mm {
    font-size: 24px;
    color: white;
    z-index: 1;
    margin-top: 20px;
  }
._1E_fau ._30x_Wh i {
      color: white !important;
    }
._1E_fau ._30x_Wh {
    z-index: 2;
    position: static;
}
._1WvLij {
}
._1Tihmn ._38deLx {
    padding: 0;
  }
.vtG7Bi ._2Gm-mm {
    font-size: 24px;
    text-align: left;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 10px;
  }
.vtG7Bi ._30x_Wh {
    top: 5px;
    right: 5px;
  }
