._2e76JA {
  display: block;
  color: #428bca;
  transition: color 0.2s ease-out
}
._2e76JA:hover,
  ._2e76JA:focus {
    color: #2f6fa8;
  }
.DiyjBU {
  color: #212121;
  font-size: 48px;
  font-weight: 400;
  margin: 0 25px 1em
}
@media all and (max-width: 600px) {
.DiyjBU {
    font-size: 30px
}
  }
