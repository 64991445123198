._1PbA5E {
  background: white;
  padding-top: 14px;
  min-width: 60px;
  position: relative;
}
._2xOEka {
  font-weight: bold;
  margin-right: 5px;
}
._2I0YLD {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 11px;
}
.RiPSKN {
  width: 46px;
  min-width: 46px;
  text-align: center;
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 1px #b6b6b6;
}
._1GurfF {
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
}
._3oV9Dh {
  font-size: 26px;
}
