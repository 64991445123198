.DD0BGZ {
  font-size: 18px;
}

._2ylFx0 {
  color: black;
}

._2cI7EC {
  padding: 10px 0;
}

._1mxeSq {
  padding: 15px 0;
}

._1oaSJH {
  opacity: 0.4;
}

._1mxeSq + ._1mxeSq {
  border-top: 1px solid #eeeeee;
}

._2Yn3js {
  min-height: 200px;
  padding: 40px;
  text-align: center;
}

._2UuFTo {
  padding: 10px;
}

._8VSd8U {
  margin: 0 auto;
}

._1-nCJc {
  margin-top: 20px;
}

._2L4oWV,
._2I0jGd {
  margin-top: 10px;
}
