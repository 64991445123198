._1Yk4gG {
  position: relative;
  width: 100%;
  max-width: 660px;
  padding: 20px 0 35px;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  margin: 15px 0
}
._1Yk4gG:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6px;
    background-color: #009bbb;
  }
._1XM9h3:after, ._3TXJ3u:after {
      background-color: #77216f;
    }
._1XM9h3 .gkif0X, ._3TXJ3u .gkif0X {
      border: 1px solid #77216f;
    }
._1XM9h3 ._1gBLMU, ._3TXJ3u ._1gBLMU {
      border-right: 1px dashed #77216f;
    }
._1XM9h3 .mdkSyi, ._3TXJ3u .mdkSyi {
      color: #77216f;
      fill: #77216f;
    }
._3xbaUz {
    max-width: 600px;
  }
._3l0uBv {
  width: 100%;
  display: table;
}
._1gBLMU,
._3roNIU {
  width: 50%;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  padding: 10px 20px 10px 45px;
}
._1gBLMU {
  border-right: 1px dashed #4d4f53;
}
._3ItC62,
.N5uYGl {
  display: inline-block;
  vertical-align: middle;
}
._3ItC62 {
  margin-right: 20px;
}
.N5uYGl {
  width: calc(100% - 45px);
}
.gkif0X {
  position: relative;
  width: 24px;
  height: 24px;
  margin: auto;
  border: 1px solid #009bbb;
  border-radius: 24px;
  background-color: #ffffff;
}
.gkif0X i {
    margin: 3px 4px;
    font-size: 15px;
  }
.gkif0X svg {
    margin: 6px;
  }
.mdkSyi {
  width: 10px;
  height: 10px;
  color: #009bbb;
  fill: #009bbb;
}
._3pC2R7 {
  height: 55px;
  width: 46px;
  border-right: 1px dashed #4d4f53;
}
._2laYme,
._4VBCtU,
._2ldnuc,
._1stGEi {
  color: #4d4f53;
  font-size: 12px;
  font-weight: 100;
}
._2laYme {
  padding-left: 45px;
  text-transform: uppercase;
}
._2ldnuc {
  color: #4d4f53;
  font-weight: 500;
}
._2m6DbX {
  margin-top: 30px;
}
._4Sg44L {
  color: #002663;
  font-size: 12px;
}
.hgym4u,
._80Nd5o {
  font-size: 14px;
  color: #4d4f53;
  font-weight: 100;
}
.hgym4u {
  padding-right: 20px;
}
._2r8NOr {
  margin-top: 40px;
  padding: 0 30px;
  text-align: center;
}
._3xp5Fy {
  background-color: rgba(119, 33, 111, 0.1);
  border-radius: 5px;
  padding: 20px;
}
._1Hs33O {
  width: 100%;
  display: table;
}
._1xhmaW,
._3DpDqD,
.i-ebhC {
  display: table-cell;
  vertical-align: top;
}
._1xhmaW {
  text-align: left;
}
._3DpDqD {
  text-align: center;
  font-size: 12px;
}
.i-ebhC {
  text-align: right;
}
._6mGw2r {
  margin-top: 35px;
  color: #8b8d8e;
}
._80Nd5o {
  font-size: 16px;
}
._1BuVw6,
.oYgESM {
  font-size: 10px;
  color: #4d4f53;
}
.oYgESM {
  color: #8b8d8e;
}
._3rfLTC {
  margin-top: 30px;
  font-size: 24px;
  color: #009bbb;
  display: inline-block;
  float: right;
  cursor: pointer;
}
._3kxOk9 {
  font-size: 12px;
  text-align: right;
  margin-top: 10px;
}
.X2ds4w {
  color: #4d4f53;
  font-weight: bold;
}
.wWGLem {
  display: inline-block;
}
._3TXJ3u ._3xp5Fy {
      background-color: #ededed;
    }
._3xbaUz ._1gBLMU,
    ._3xbaUz ._3roNIU {
      padding: 0;
    }
._3xbaUz ._1gBLMU {
      width: 70px;
      border: none;
    }
._3xbaUz ._3pC2R7 {
      width: 36px;
    }
._3xbaUz ._3roNIU {
      width: auto;
    }
._3xbaUz ._2laYme,
    ._3xbaUz ._1stGEi {
      color: #8b8d8e;
    }
._3xbaUz ._2laYme {
      padding-left: 0;
    }
._3xbaUz .KHXWva {
      margin-bottom: 40px;
    }
._3xbaUz ._2r8NOr {
      margin-top: 0;
    }
