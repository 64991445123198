._1cP0_5 {
  color: #212121;
  font-size: 48px;
  font-weight: 400;
  margin-bottom: 1em;
}
._2RSqAI {
  padding-top: 40px;
}
._2RSqAI button {
    margin-bottom: 5px
  }
._2RSqAI button:first-child {
      margin-right: 15px;
    }
._1GX6MA {
  margin-top: 40px;
  padding: 40px;
  max-width: 40%;
  min-width: 380px;
  background: #ffffff;
  box-shadow: 2px 2px 10px -2px #000000;
  border: transparent;
  border-radius: 4px;
}
._1UWEVV {
  border-radius: 0;
}
._3hQ3Wo {
}
._3o945f {
}
._1t4GD8 {
  margin: 80px 0 50px;
}
._1eHlJg {
  margin: 20px 5px;
  text-decoration: underline;
  display: inline-block;
  color: #009bbb;
}
