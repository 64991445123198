._3kDbP9 {
}

._3kDbP9._3FQeKo ._3Bdal0 {
    white-space: normal;
  }

._3kDbP9._3p-p9B ._3Bdal0 {
    text-transform: lowercase
  }

._3kDbP9._3p-p9B ._3Bdal0:first-letter {
      text-transform: uppercase;
    }

._3kDbP9._1RsuZY ._3Bdal0 {
    padding-left: 5px;
  }

._3kDbP9._17_SlR {
  max-width: 0;
  min-width: 100%;
}

._3kDbP9._17_SlR ._3Bdal0 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

._3kDbP9._2tT8oI {
  padding: 0;
}

.DxhrIM {
  margin-right: 4px;
}

._3Bdal0 {
  padding-right: 5px;
  white-space: nowrap;
  position: relative;
}

._2Y65lv {
  font-size: 12px;
}

._3kDbP9._2iYDKi {
  padding: 0;
}

._3kDbP9._2iYDKi ._3Bdal0 {
    padding: 0;
  }
