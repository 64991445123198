._2vZ6Eh {
}

._1RgRV- {
}

._1RgRV-,
._2lZtnH:after {
  display: inline-block;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
  visibility: hidden;
}

._2lZtnH:after {
  font-family: 'Material Icons';
}

._3CfBHa {
  width: 24px;
  height: 24px;
}

span._3CfBHa {
  text-align: center;
}

._3CfBHa._3UXQXA {
  width: 40px;
  height: auto;
}

._1dL8Su {
  font-family: 'Material Icons';
}

._3G1rmb {
}

._3DFZQH {
}

._3OeRJa {
  border-radius: 50%;
  font-size: 12px;
  width: 24px;
  height: 24px;
  font-weight: 700;
  border-width: 1px;
}

.wf-active ._1RgRV- {
    visibility: visible;
  }

/* tilt */

._2vZ6Eh._1Uieay {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

/* interactive */

._2g7jUv {
}

._2vZ6Eh._2g7jUv {
  cursor: pointer;
  transition: color 0.2s !important;
}

._1RgRV-._2g7jUv {
  font-size: 18px;
}

._1RgRV-._2g7jUv._2-20kZ {
  font-size: 14px;
}

._1RgRV-._2g7jUv._3UXQXA {
  font-size: 24px;
}

._3CfBHa._2g7jUv {
  padding: 1px;
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
}

._3OeRJa._2g7jUv {
  width: 18px;
  height: 18px;
  font-size: 10px;
}

/* small */

._1RgRV-._2-20kZ,
._1RgRV-.pnW_NB._2-20kZ {
  font-size: 14px;
}

._3CfBHa._2-20kZ,
._3CfBHa.pnW_NB._2-20kZ {
  width: 14px;
  height: 14px;
}

._3OeRJa._2-20kZ,
._3OeRJa.pnW_NB._2-20kZ {
  width: 14px;
  height: 14px;
  font-size: 9px;
}

/* light */

._1RgRV-._3O7Q_W {
  color: rgba(0, 0, 0, 0.2);
}

._3CfBHa._3O7Q_W {
  fill: rgba(0, 0, 0, 0.2);
}

._3OeRJa._3O7Q_W {
  color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
}

/* inline */

._2vZ6Eh.pnW_NB {
  margin: 0 4px;
}

/* label */

._1RgRV-._1nEzJD {
  font-size: 18px;
}

._3CfBHa._1nEzJD {
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
}

._3OeRJa._1nEzJD {
  width: 16px;
  height: 16px;
  font-size: 8px;
}

._1RrOEt {
}

._36mxo7 {
}

.GY-JJn {
}

.ZxAcmy {
}
