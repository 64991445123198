._1qNmJR {
  background: #ffffff;
  z-index: 1;
  border-radius: 3px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.45);
}
._1TEZKI {
  padding: 52px 30px 0 30px;
}
/* Important for IE */
.Lm1S0E {
  width: 100%;
  max-width: 474px;
}
.TCLaub,
._34AD_t,
.Mw3nNx {
  margin-bottom: 20px;
}
._34AD_t {
  font-size: 24px;
  text-align: left;
}
._2t_6d1,
.Mw3nNx {
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
}
.IYBNe9 {
  width: 100%;
  padding: 20px 30px;
  border-top: 1px solid #ddd;
  text-align: right;
}
._35QCZh {
  font-size: 18px;
  font-weight: 500;
}
.mobile ._34AD_t {
    display: none;
  }
.mobile .IYBNe9 {
    border-top: none;
    padding: 10px;
  }
.mobile ._35QCZh {
    width: 100%;
  }
