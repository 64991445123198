._2xA0tK {
  position: relative;
  margin: auto auto;
  /* width: 300px; */
  /* height: 300px; */
}
.rWgJVy {
  width: 10px;
  height: 10px;
  background: #428bca;
  border-radius: 30px;
  position: absolute;
  left: -5px;
  top: -5px;
  -webkit-animation: _231yWO linear 3s;
          animation: _231yWO linear 3s;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
._2neurf,
._3jIDmN {
  width: 120px;
  height: 120px;
  border-radius: 200px;
  position: absolute;
  left: -60px;
  top: -60px;
  opacity: 0;
  -webkit-animation: _2UBUdB cubic-bezier(0, 0.54, 0.53, 1) 3s;
          animation: _2UBUdB cubic-bezier(0, 0.54, 0.53, 1) 3s;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
._2neurf {
  background: rgba(32, 150, 243, 0.4);
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
._3jIDmN {
  background: rgba(32, 150, 243, 0.4);
  -webkit-animation-delay: 1.07s;
          animation-delay: 1.07s;
}
._2eiyum {
  position: absolute;
  font-size: 40px;
  top: -45px;
  left: -20px;
}
/* Based on http://codepen.io/anon/pen/ZGvavq */
@-webkit-keyframes _2UBUdB {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  1% {
    opacity: 1;
  }

  10% {
    background: rgba(32, 150, 243, 0.4);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background: rgba(32, 150, 243, 0);
  }
}
@keyframes _2UBUdB {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  1% {
    opacity: 1;
  }

  10% {
    background: rgba(32, 150, 243, 0.4);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background: rgba(32, 150, 243, 0);
  }
}
@-webkit-keyframes _231yWO {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }

  28% {
    background: rgb(32, 150, 243);
  }
  29% {
    background: white;
  }
  31% {
    background: rgb(32, 150, 243);
  }
  33% {
    background: white;
  }
  35% {
    background: rgb(32, 150, 243);
  }
  100% {
    opacity: 1;
  }
}
@keyframes _231yWO {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }

  28% {
    background: rgb(32, 150, 243);
  }
  29% {
    background: white;
  }
  31% {
    background: rgb(32, 150, 243);
  }
  33% {
    background: white;
  }
  35% {
    background: rgb(32, 150, 243);
  }
  100% {
    opacity: 1;
  }
}
