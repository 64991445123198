@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  src: url(/50db936a55708c077ad4fc567f58ad90.woff2) format('woff2'),
    url(/f27e2017bb70cedb8b63ae10e8c59958.woff) format('woff'),
    url(/7b5fb88f12bec8143f00e21bc3222124.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url(/bde1ca6a5d7cefc8108c75fdaad29ed6.woff2) format('woff2'),
    url(/d60c6fd1200bb6fe2050f4296ace8d43.woff) format('woff'),
    url(/ac3f799d5bbaf5196fab15ab8de8431c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url(/574fd0b50367f886d359e8264938fc37.woff2) format('woff2'),
    url(/fc78759e93a6cac50458610e3d9d63a0.woff) format('woff'),
    url(/894a2ede85a483bf9bedefd4db45cdb9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url(/7a7f7b407423d5fb3c16b26231e11619.woff2) format('woff2'),
    url(/d475e33066671b6e274cf81e2f5c4d68.woff) format('woff'),
    url(/d329cc8b34667f114a95422aaad1b063.ttf) format('truetype');
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/178d7a4dacfb885abee8e2d905b08524.eot); /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(/81025269949a562d06d5e316f733b140.woff2) format('woff2'),
    url(/e2f35f2d8bb12d4d3407ebe3683adaa1.woff) format('woff'),
    url(/9ac46d2eb22f2f00090a73ed943b9522.ttf) format('truetype');
}
