._2tfEGS {
  background: #ffffff;
  width: 570px;
  padding: 40px;
  z-index: 1;
}
/* Important for IE */
._3CIuQM {
  max-width: 570px;
}
._1H6N0m,
._2g3QIu,
._3mize7 {
  margin-bottom: 20px;
}
._2g3QIu {
  font-size: 36px;
  text-align: center;
}
._3mize7 {
  font-size: 14px;
  text-align: center;
}
._22DLqU {
  font-size: 18px;
  font-weight: 500;
}
